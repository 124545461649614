<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Control de Proveedores</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title>
            Formulario de Datos
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-autocomplete
                  :items="itemsPais"
                  item-text="name"
                  item-value="id"
                  label="País"
                  v-model="id_pais"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Nombre del Proveedor" v-model="name">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Nombre de Contacto" v-model="contact">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Teléfono" v-model="phone"> </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-switch
                  label="Estado del Proveedor"
                  v-model="status"
                ></v-switch>
              </v-col>
              <v-col cols="6" md="6">
                <v-btn block color="error" @click="cleanData()" rounded
                  >Cancelar</v-btn
                >
              </v-col>
              <v-col cols="6" md="6">
                <v-btn
                  block
                  v-if="statusData == 1"
                  color="success"
                  @click="addData()"
                  rounded
                  >Guardar</v-btn
                >
                <v-btn
                  block
                  v-if="statusData == 2"
                  color="success"
                  @click="updateData()"
                  rounded
                  >Actualizar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title>
            Lista de Proveedores
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="itemsProveedores"
            :search="search"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item.id)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item.id)">
                mdi-delete
              </v-icon>
            </template></v-data-table
          >
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "Proveedores",
  data: () => ({
    search: "",
    itemsPais: [],
    itemsProveedores: [],
    id: "",
    id_pais: "",
    name: "",
    contact: "",
    phone: "",
    description: "",
    status: true,
    statusData: 1,

    headers: [
      {
        text: "País",
        align: "start",
        sortable: false,
        value: "pais",
      },
      { text: "Nombre", value: "name" },
      { text: "Contacto", value: "contact" },
      { text: "Teléfono", value: "phone" },
      { text: "Acciones", value: "actions" },
    ],
  }),
  mounted() {
    this.loadPais();
    this.loadProveedores();
  },
  methods: {
    loadPais() {
      axios.get(process.env.VUE_APP_API_URL + "pais").then((result) => {
        this.itemsPais = result.data;
      });
    },
    loadProveedores() {
      axios.get(process.env.VUE_APP_API_URL + "proveedores").then((result) => {
        this.itemsProveedores = result.data;
      });
    },
    editItem(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "proveedores/" + id)
        .then((result) => {
          this.id = result.data[0].id;
          this.id_pais = result.data[0].id_pais;
          this.name = result.data[0].name;
          this.contact = result.data[0].contact;
          this.phone = result.data[0].phone;
          this.status = result.data[0].status;
          this.statusData = 2;
        });
    },
    addData() {
      let data = {
        id_pais: this.id_pais,
        name: this.name,
        contact: this.contact,
        phone: this.phone,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "proveedores", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Proveedor registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadProveedores();
            this.cleanData();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    updateData() {
      let data = {
        id_pais: this.id_pais,
        name: this.name,
        contact: this.contact,
        phone: this.phone,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "proveedores/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Proveedor actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadProveedores();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, el proveedor no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "proveedores/" + id)
            .then((result) => {
              console.log(result.data);
              if (result.data.boolean == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Proveedor eliminado con éxito",
                  showConfirmButton: true,
                  timer: 5000,
                });
                this.loadProveedores();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Oops...",
                  text: result.data.error.sqlMessage,
                  showConfirmButton: true,
                  timer: 8000,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "El proveedor no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.name = "";
      this.contact = "";
      this.phone = "";
      this.status = true;
      this.statusData = 1;
    },
  },
};
</script>
